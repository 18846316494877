<script setup lang="ts">
import Logo from '~/assets/svg/logo.svg';
import {
  ref,
  watchOnce,
  watch,
  useFooterConfig,
  useNavigation,
  useConfigStore,
  useGeneralService,
  useElementVisibility,
  useDevice,
} from '#imports';

const {
  storeLinks,
  socmedLinks,
  showGlobalHolidayLicense,
  showAustraliaLicense,
  showAvailablePayments,
  onClickDownloadBanner,
  onClickSocialMedia,
} = useFooterConfig();
const { navigationPath } = useNavigation();
const config = useConfigStore();
const device = useDevice();

const footer = ref(null);
const isFooterVisible = useElementVisibility(footer);

const { data: availablePayments, execute: fetchAvailablePayments } = useAsyncData(
  () => useGeneralService().getAvalaiblePaymentMethods({ currency: config.currency! }),
  {
    server: device.isCrawler,
    immediate: device.isCrawler,
    transform: (data) => data.result.payments,
  },
);

watchOnce(isFooterVisible, () => fetchAvailablePayments());

watch(
  () => config.currency,
  () => fetchAvailablePayments(),
);
</script>

<template>
  <footer
    ref="footer"
    class="bg-white pb-50 pt-30"
  >
    <nav class="flex flex-col items-center">
      <template v-if="showAvailablePayments">
        <div class="text-base font-bold mb-15">
          {{ $t('global.paymentacceptedmethod') }}
        </div>

        <div
          class="w-full grid grid-flow-col overflow-x-scroll scrollbar-hide gap-20 mb-20 px-20"
          data-testid="accepted-payment-method"
          :class="[(availablePayments?.length || 0) > 5 ? 'grid-rows-[auto_auto] gap-20' : 'gap-5']"
        >
          <div
            v-for="payment in availablePayments"
            :key="payment.alt"
            class="flex items-center w-[80px] h-[31px] self-center justify-self-center"
          >
            <NuxtImg
              :alt="payment.alt"
              :src="payment.url"
              width="80"
              height="31"
              loading="lazy"
            />
          </div>
        </div>
      </template>

      <div class="text-base font-bold mb-15">
        {{ $t('global.socialmedia') }}
      </div>

      <div class="flex gap-10 mb-20">
        <NuxtLink
          v-for="socmed in socmedLinks"
          :key="socmed.id"
          :to="socmed.to"
          aria-label="Link to Social Media"
          target="_blank"
          rel="noopener"
          class="flex justify-center items-center text-gray-light w-[30px] h-[30px] bg-whiter rounded"
          :data-testid="'soscialMedia-' + socmed.id + '-logo'"
          @click="onClickSocialMedia(socmed.id)"
        >
          <ApzIcon :icon="['fab', socmed.icon]" />
        </NuxtLink>
      </div>

      <div class="text-base font-bold mb-15">
        {{ $t('global.download') }}
      </div>

      <div class="flex gap-10 mb-45">
        <NuxtLink
          v-for="store in storeLinks"
          :key="store.img"
          target="_blank"
          :to="store.link"
          :data-testid="'download-' + store.img + '-button'"
          @click="onClickDownloadBanner(store.event)"
        >
          <NuxtImg
            :src="'/img/' + store.img"
            :alt="store.alt"
            width="70"
            height="21"
            loading="lazy"
          />
        </NuxtLink>
      </div>

      <div class="flex flex-col items-center">
        <NuxtLink
          :to="navigationPath('/')"
          aria-label="Link to Home Page"
          class="mb-10"
          external
          no-rel
        >
          <Logo
            class="text-primary"
            width="108"
          />
        </NuxtLink>

        <div
          class="text-center text-xsmall text-gray-dark"
          data-testid="footer-copyright-text"
        >
          {{ $t('global.copyrighttitle') }}
          <br />
          <template v-if="showGlobalHolidayLicense">Global Airlines Holiday Sdn Bhd 105929-H</template>
          <template v-else-if="showAustraliaLicense">
            <div>Airpaz Australia Pty Ltd , 21 Dunkerley Pl , Waterloo, NSW, 2017 , Australia</div>
            <NuxtLink
              :to="navigationPath('/privacy-policy')"
              external
              no-rel
              class="underline"
            >
              {{ $t('global.privacypolicy') }}
            </NuxtLink>
          </template>
        </div>
      </div>
    </nav>
  </footer>
</template>
